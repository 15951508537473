/* @import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@500&display=swap');
* {
  font-family: 'Fira Sans', sans-serif;
} */
.App-header {
  background-color: rgb(207, 207, 207);
  box-shadow: 4px 4px 10px rgba(45, 45, 45, 0.665);
  color: #7c84ae;
}

/* button {
  background-color: #ffb4bf;
  border: 3px solid var(--fourth-color);
  border-radius: 4px;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: filter 0.3s ease;

  &:hover {
    filter: brightness(0.1);
  }

  &:active {
    transform: scale(0.95);
  }
} */
